import React from "react";
const Footer = () => {

   
   return (
        <div className="footer-container">
            <div className="footer-text">
            <h7 style={{ fontFamily: "sans-serif" }}>© 2024 All Rights Reserved</h7>
            <br/>
           
            <h7 style={{ fontFamily: "sans-serif" }}>Made by Prateek Balani</h7>
            </div>

          
        </div>
    );
};

export default Footer;
